@font-face {
  font-family: "Metropolis Black";
  src: url("../fonts/metropolis/metropolis-black-webfont.woff2") format("woff2"),
    url("../fonts/metropolis/metropolis-black-webfont.woff") format("woff"),
    url("") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis Light";
  src: url("../fonts/metropolis/metropolis-light-webfont.woff2") format("woff2"),
    url("../fonts/metropolis/metropolis-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis Medium";
  src: url("../fonts/metropolis/metropolis-medium-webfont.woff2")
      format("woff2"),
    url("../fonts/metropolis/metropolis-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis Regular";
  src: url("../fonts/metropolis/metropolis-regular-webfont.woff2")
      format("woff2"),
    url("../fonts/metropolis/metropolis-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis Semibold";
  src: url("../fonts/metropolis/metropolis-semibold-webfont.woff2")
      format("woff2"),
    url("../fonts/metropolis/metropolis-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* COLOR PALETTE #e7ecef, #274c77, #e54e40, #6096ba, #a3cef1 */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-x: hidden;
  line-height: 1.3; /* Overide normalize */
  scroll-behavior: smooth;
}

body {
  background: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* ------------------------- */

main {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.section {
  height: 100vh;
  padding: 70px;
  position: relative;
  width: 100%;
  scroll-snap-align: start;
}

body::-webkit-scrollbar {
  display: none;
}

.sidebar .scene {
  width: 35%;
  height: 60px;
  transform: translateY(-5px);
}

.carousel {
  z-index: -1;
  width: 35%;
  height: 75px;
  position: absolute;
  transform: translateZ(-110px);
  transform-style: preserve-3d;
  transition: transform 1s;
  opacity: 1;
  font-family: "Metropolis Semibold";
}

.carousel.visible {
  opacity: 1;
}

.carousel-cell {
  position: absolute;
  width: 430px;
  height: 80px;
  top: 0px;
  opacity: 0;
  line-height: 80px;
  font-size: 45px;
  font-family: "Metropolis Semibold", sans-serif;
  color: #e54e40;
  text-align: left;
  transition: opacity 1s;
  animation-name: fadeIn;
  overflow-wrap: break-word;
}

.carousel-cell.visible {
  opacity: 1;
}

.carousel-cell:nth-child(1) {
  transform: rotateX(0deg) translateZ(40px);
}
.carousel-cell:nth-child(2) {
  transform: rotateX(90deg) translateZ(40px);
}
.carousel-cell:nth-child(3) {
  transform: rotateX(180deg) translateZ(40px);
}
.carousel-cell:nth-child(4) {
  transform: rotateX(270deg) translateZ(40px);
}

.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.8);
}

.home-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
  transition: transform 0.5s;
  transform: translateX(0px);
  pointer-events: none;
}

.sidebar {
  position: relative;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.8)
  );
  bottom: 0;
  left: 0;
  top: 0;
  width: 33vw;
  min-width: 525px;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.075);
  z-index: 4;
  transition: transform 0.5s;
}

.sidebar .top {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 70px;
  position: absolute;
  right: 70px;
  top: 0;
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 150ms;
}

.collapsed .sidebar .top {
  opacity: 0;
  transition-delay: 0ms;
}

.sidebar .title {
  display: flex;
  flex-direction: column;
  font-family: "Metropolis Light", sans-serif;
  font-size: 45px;
}

.sidebar .title .name {
  color: #505050;
}

.sidebar .title .role {
  color: #e54e40;
}

.sidebar .title .underscore {
  max-width: 100px;
  min-width: 100px;
  border-bottom: 3px solid #e54e40;
  padding-top: 60px;
}

.story {
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  position: relative;
  width: 100%;
  color: #4d4d4f;
  font-size: 14px;
}

.portfolio-button-wrapper {
  bottom: 70px;
  left: 70px;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  z-index: 3;
  padding-top: 40px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition: box-shadow 0.25s;
  transition: transform 0.05s;
}

.portfolio-button {
  align-items: center;
  color: #ffffff;
  background-color: #e54e40;
  border: 3px solid #e54e40;
  padding: 2px 10px;
  display: flex;
  cursor: pointer;
  user-select: none;
  transition: all 250ms;
}

.portfolio-button-wrapper:hover,
.portfolio-button-wrapperfocus,
.portfolio-button-wrapperactive {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-in-out, ease-in-out;
  animation-timing-function: ease-in-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: alternate, alternate;
  animation-direction: alternate, alternate;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.1);
  transition: all 0.25s;
}

.portfolio-button .button-content {
  font-family: "Metropolis Semibold", sans-serif;
  font-size: 12px;
  margin-top: 6px;
  padding-right: 10px;
}

.portfolio-button .button-graphic {
  min-height: 10px;
  min-width: 6px;
}

.portfolio-button .button-graphic g#website_1 {
  fill: #ffffff;
}

.navbar {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.075);
  pointer-events: all;
  color: #505050;
  display: flex;
  flex-direction: column;
  font-family: "Metropolis Regular", sans-serif;
  height: 70px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100vw;
  top: 0;
  z-index: 3;
}

.links {
  position: relative;
  transform: translateX(0);
  transition: transform 0.5s;
  transition-delay: 25ms;
  align-items: center;
  display: flex;
}

.links-spacer {
  transition: width 0.5s;
}

.links a {
  white-space: nowrap;
}

.collapsed .navbar .links {
}

.link {
  font-size: 15px;
  padding: 0 0 0 35px;
  transition: color 200ms;
  cursor: pointer;
  user-select: none;
  word-break: keep-all;
}

.link:hover {
  color: #e54e40;
  font-weight: 500;
  transition: color 0.2s;
}

.section.top {
  max-height: 100vh;
  background-color: #ffffff;
}

.logo {
  position: fixed;
  top: 0px;
  left: 0px;
  background: #e54e40 url(../assets/svgs/logo.svg) no-repeat center center;
  background-size: 50px;
  color: #ffffff;
  height: 70px;
  width: 70px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  z-index: 6;
}

.darkmode-toggle {
  position: fixed;
  top: 0px;
  right: 0px;
  background: url(../assets/svgs/darkmode.svg) no-repeat center center;
  background-size: 20px;
  opacity: 1;
  transition: all 0.2s;
  height: 70px;
  width: 70px;
  z-index: 4;
  transition-delay: 250ms;
  cursor: pointer;
}

.collapsed .darkmode-toggle {
  transition-delay: 0ms;
  opacity: 0;
  pointer-events: none;
}

.section.top .splash-container {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 2fr;
  top: 0;
  right: 70px;
  left: 70px;
  bottom: 70px;
  overflow: hidden;
  object-fit: cover;
}

.section.top .splash-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-column-start: 2;
}

.section.top .splash-wrapper img {
  position: absolute;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: center;
  z-index: 1;
}

.section.top .header {
  position: relative;
  left: 50px;
  top: 50vh;
  color: #4d4d4f;
  font-size: 50px;
  font-family: "Ailerons", sans-serif;
  text-transform: uppercase;
  transform: translateY(-50%);
}

.scroll-button {
  position: fixed;
  width: 70px;
  height: 100vh;
  right: 0;
  z-index: 5;
}

.scroll-button .scroll-up:hover,
.scroll-button .scroll-down:hover {
  background: #e54e40;
  animation: none;
}
.scroll-up:hover svg path,
.scroll-down:hover svg path {
  fill: #ffffff;
}

.scroll-button .scroll-up {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  right: 0;
  top: 0;
  z-index: 3;
  animation: bounce-2 2s infinite;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  transition-delay: 50ms;
  pointer-events: none;
}

.collapsed .scroll-up {
  opacity: 1;
  pointer-events: auto;
}

.scroll-button .scroll-down {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  right: 0;
  bottom: 0px;
  z-index: 3;
  animation: bounce 2s infinite;
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;
}

.scroll-button svg {
  width: 17.5px;
}

.portfolio-button:hover .button-graphic,
.resume-button:hover .button-graphic,
.tagline-button:hover .button-graphic {
  animation: bounce-3 1s infinite;
}

.link-underscore {
  position: absolute;
  width: 30px;
  margin-top: 15px;
  margin-left: 35px;
  border: 0;
  transform: translateX(0);
  border-top: 3px solid #e54e40;
  opacity: 1;
  transition: opacity 250ms;
}

.scroll-indicator {
  position: fixed;
  width: 10px;
  left: 30px;
  top: calc(50vh - 61.65px);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.2s;
}

.collapsed .scroll-indicator {
  opacity: 1;
}

.lightbox-effect .scroll-indicator {
  visibility: hidden;
}

.enter-section-1 .scroll-indicator .dot-1 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.enter-section-1 .scroll-indicator .dot-1 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-2 .scroll-indicator .dot-2 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-2 .scroll-indicator .dot-2 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-3 .scroll-indicator .dot-3 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-3 .scroll-indicator .dot-3 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-4 .scroll-indicator .dot-4 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-4 .scroll-indicator .dot-4 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-5 .scroll-indicator .dot-5 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-5 .scroll-indicator .dot-5 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-6 .scroll-indicator .dot-6 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-6 .scroll-indicator .dot-6 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.enter-section-7 .scroll-indicator .dot-7 {
  fill: #e54e40;
  transition: fill 0.2s;
}
.exit-section-7 .scroll-indicator .dot-7 {
  fill: #d1d3d4;
  transition: fill 0.2s;
}

.section.portfolio {
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.075);
  height: 100vh;
  display: flex;
  align-items: center;
  padding-right: 0;
}

.section.portfolio .project-header {
  position: absolute;
  height: 100%;
  width: calc(33vw - 140px);
  min-width: 455px;
  padding: 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.075);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.75)
  );
  backdrop-filter: blur(10px);
  z-index: 2;
}

.section.portfolio .project-header .title {
  font-family: "Metropolis Regular";
  font-size: 45px;
  color: #505050;
  padding-right: 15px;
}

.section.portfolio .project-header .content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.section.portfolio .filter-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 385px;
  height: 35px;
  background-color: #0000000d;
  border-radius: 17.5px;
  overflow: hidden;
}
.section.portfolio .filter-cancel {
  position: absolute;
  opacity: 0;
  width: 35px;
  height: 35px;
  display: flex;
  left: -32px;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.section.portfolio .filter-cancel svg {
  width: 10px;
  height: 10px;
  fill: #e54e40;
  cursor: pointer;
  pointer-events: none;
}

.section.portfolio .filter-highlight.enabled {
  opacity: 1;
}
.section.portfolio .filter-highlight {
  position: absolute;
  height: 100%;
  width: 25%;
  background-color: #e54e40;
  z-index: -1;
  border-radius: 17.5px;
  transform: translateX(-100%);
  transition: all 0.35s ease-in-out;
  opacity: 0;
}

.section.portfolio .filter {
  font-family: "Metropolis Regular";
  color: #a0a0a0;
  font-size: 0.8em;
  padding: 12px 0;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.35s;
}

.tags-container {
  position: absolute;
  top: 17.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 17.5px;
  opacity: 0;
  transition: opacity 0.2s;
}

.project-tile-wrapper:hover .tags-container {
  opacity: 1;
}

.project-tag {
  font-family: "Metropolis Regular";
  display: flex;
  opacity: 0.95;
  align-items: center;
  color: #fff;
  height: 22.5px;
  font-size: 0.8em;
  margin-right: 10px;
  padding: 4px 12.5px 0 12.5px;
  border-radius: 22.5px;
  text-align: center;
  user-select: none;
}

.section.portfolio .filter.current {
  color: #ffffff;
  mix-blend-mode: normal;
}

.section.portfolio .projects-grid-wrapper {
  overflow-x: scroll;
  position: relative;
  display: block;
  width: auto;
  height: 100vh;
  right: 0;
}

.section.portfolio .projects-grid {
  display: grid;
  position: relative;
  grid-template-columns: auto repeat(6, calc(((100vh - 140px) / 3) * 2));
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  grid-template-areas:
    "placeholder project-1 project-3 project-5 project-7 project-9 project-11"
    "placeholder project-1 project-4 project-5 project-8 project-9 project-12"
    "placeholder project-2 project-4 project-6 project-8 project-10 project-12";
  justify-content: start;
  width: auto;
  padding: 70px 0 70px 0;
  height: 100vh;
}

.section.portfolio .projects-grid .project-label {
  position: absolute;
  font-family: "Metropolis Regular";
  color: #505050;
  font-size: 15px;
  opacity: 1;
  transform: translateX(0);
  padding-left: 17.5px;
  bottom: 20px;
  z-index: 3;
}

.projects-grid .project-label-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.35),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  );
  transition: opacity 0.2s;
  z-index: -1;
}

.project-tile-wrapper:hover .project-label-shadow {
  opacity: 1;
}

.project-tile_description:hover {
  opacity: 1;
}

.section.portfolio .projects-grid .project-label .project-tile_title {
  font-size: 1em;
  color: #ffffff;
  font-weight: 500;
}

.section.portfolio .projects-grid .project-label .project-tile_description {
  font-size: 1em;
  color: #ffffff;
  font-weight: 300;
  margin-top: 10px;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.section.portfolio .projects-grid .grid-placeholder {
  width: calc(33vw - 140px);
  min-width: 455px;
  grid-area: placeholder;
}

.section.portfolio .projects-grid .project-tile-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-self: stretch;
  overflow: hidden;
  cursor: pointer;
  /* animation: fadeIn 0.75s; */
  transform: scale3d(1, 1, 1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.section.portfolio .projects-grid .project-tile-wrapper:hover {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  /* transform: scale3d(1.02, 1.02, 1.02); */
}

.section.portfolio .scroll-block {
  position: absolute;
  width: 70px;
  height: 100%;
  right: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.5)
  );
  z-index: 1;
}

.section.portfolio .projects-grid .project-image-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  height: 100%;
}

.section.portfolio .projects-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  cursor: pointer;
}

.projects-container .project-tile-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.project-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 70px;
  backdrop-filter: blur(10px);
  z-index: 5;
  opacity: 1;
  animation: fadeIn 0.4s;
  transition: all 0.2s;
}

.project-modal .backdrop:hover {
  opacity: 1;
}

.project-modal .backdrop {
  animation: fadeIn 0.4s;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  transition: opacity 0.2s;
}

.project-modal .close-button-corner {
  position: absolute;
  left: 70px;
  top: 70px;
  width: 200px;
  height: 200px;
  opacity: 0;
  background: rgba(1, 1, 1, 1);
  background: radial-gradient(
    farthest-side at 0% 0%,
    rgba(25, 25, 25, 0.7),
    rgba(25, 25, 25, 0.5),
    rgba(25, 25, 25, 0)
  );
  transition: opacity 0.2s;
}
.project-modal .close-button-corner:hover {
  opacity: 1;
}
.project-modal .close-button-corner:hover svg {
  opacity: 1;
}

.project-modal .close-button-corner svg {
  fill: #fff;
  width: 57.5px;
  height: 57.5px;
  left: 12.5px;
  top: 12.5px;
  padding: 20px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s;
}

.close-button-corner-label {
  font-family: "Metropolis Light";
  font-size: 12.5px;
  position: absolute;
  top: 35px;
  left: 70px;
  user-select: none;
}

.project-modal .close-button {
  font-family: "Metropolis Medium";
  font-weight: 500;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
  color: #e54e40;
  height: 70px;
  width: auto;
  position: absolute;
  bottom: 0;
  font-size: 1em;
  margin-right: 10px;
  text-align: center;
  user-select: none;
}

.project-modal .content {
  width: 100%;
  height: calc(100%);
  overflow: scroll;
  scroll-behavior: smooth;
  background-color: #d1d3d4;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.075);
}

.project-modal .project-document {
  width: 100%;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.075);
}

.section.about-me {
  display: flex;
  max-height: 100vh;
}

.section.about-me .left {
  display: flex;
  flex: 0 0;
}

.section.about-me .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 70px 0 0 70px;
}

.section.about-me .splash {
  flex: 1;
  min-width: 275px;
  background: url(../assets/imgs/splash_2.jpg) no-repeat center center;
  background-size: cover;
}

.section.about-me .content {
  display: flex;
  padding-top: 70px;
}

.section.about-me .title {
  display: flex;
  flex-direction: column;
}

.section.about-me .title .header {
  font-family: "Metropolis Regular";
  font-size: 37px;
  color: #505050;
}

.section.about-me .title .header.sub {
  color: #e54e40;
}

.section.about-me .title .underscore {
  border-top: 4px solid #e54e40;
  margin-top: 40px;
  max-width: 100px;
  min-width: 100px;
}

.section.about-me .half {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  position: relative;
}
.section.about-me .half:first-child {
  padding-left: 0;
}
.section.about-me .half:last-child {
  padding-right: 0;
}

.section.about-me .half .graphics {
  display: flex;
  height: 300px;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}

.section.about-me .half .graphic {
  background: no-repeat center center;
  background-size: contain;
  flex: 1;
}

/* .section.about-me .ui-ux .graphic.first {
  background-image: url(/UX_UI_1.svg);
}

.section.about-me .ui-ux .graphic.second {
  background-image: url(/VCD_1.svg);
}

.section.about-me .industrial .graphic.first {
  background-image: url(/Sketching_1.svg);
}

.section.about-me .industrial .graphic.second {
  background-image: url(/CAD_1.svg);
} */

.section.about-me .half .caption {
  font-family: "Metropolis Light";
  font-size: 15px;
  padding: 30px 30px;
  color: #505050;
  overflow-y: scroll;
}

.section.about-me .half .caption::-webkit-scrollbar {
  display: hidden;
}
